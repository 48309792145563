/* eslint-disable */
import i18n from "@/main.js";

export default [
  {
    title: i18n.t("navigation.user-management"),
    titleEng: "Role Management",
    route: "admin-management",
    icon: "UserIcon",
    id: 1,
  },
  {
    title: i18n.t("navigation.role-management"),
    titleEng: "Role Management",
    route: "role-management",
    icon: "UserCheckIcon",
    id: 2,
    children: [
      {
        title: i18n.t("navigation.role-management"),
        route: "role-management",
        allowedRoles: [
          "Admin",
          "Content Manager",
          "Operational Manager",
          "Accountant",
          "Service Provider",
        ],
      },
      {
        title: i18n.t("navigation.vendor-users"),
        route: "vendor-users",
        allowedRoles: [
          "Admin",
          "Content Manager",
          "Operational Manager",
          "Accountant",
          "Service Provider",
        ],
      },
    ],
  },
  {
    title: i18n.t("navigation.products"),
    titleEng: "Products",
    route: "products",
    icon: "ShoppingBagIcon",
    id: 3,
    children: [
      {
        title: i18n.t("navigation.products-products"),
        route: "approved-products",
        allowedRoles: [
          "Admin",
          "Content Manager",
          "Operational Manager",
          "Accountant",
          "Service Provider",
        ],
      },
      {
        title: i18n.t("navigation.products-review"),
        route: "pending-products",
        allowedRoles: ["Admin", "Content Manager", "Operational Manager"],
      },
      {
        title: i18n.t("navigation.products-register"),
        route: "registerProduct",
        allowedRoles: ["Admin", "Content Manager"],
      },
      {
        title: i18n.t("navigation.stickers-register"),
        route: "registerSticker",
        allowedRoles: ["Admin", "Content Manager"],
      },
    ],
  },
  {
    title: i18n.t("navigation.orders"),
    titleEng: "Orders",
    route: "orders",
    icon: "ShoppingCartIcon",
    id: 4,
  },
  // {
  //   title: i18n.t("navigation.customers"),
  //   titleEng: "Customers",
  //   route: "customers-management",
  //   icon: "UserIcon",
  //   id: 1,
  // },
  // lmao i'm not doing this
  // {
  //   title: i18n.t("navigation.analytics"),
  //   titleEng: "Analytics",
  //   route: "analytics",
  //   icon: "PieChartIcon",
  //   id: 5,
  // },
  {
    title: i18n.t("navigation.notifications"),
    titleEng: "Notification Module",
    route: "notification",
    icon: "BellIcon",
    id: 8,
  },
  {
    title: i18n.t("navigation.parameters"),
    titleEng: "Parameters",
    route: "parameters",
    icon: "SettingsIcon",
    id: 9,
    children: [
      {
        title: "Add Regions",
        route: "add-regions",
        allowedRoles: [
          "Admin",
          "Content Manager",
          "Operational Manager",
          "Accountant",
          "Service Provider",
        ],
      },
      {
        title: "Regions",
        route: "regions",
        allowedRoles: [
          "Admin",
          "Content Manager",
          "Operational Manager",
          "Accountant",
          "Service Provider",
        ],
      },
    ],
  },
  {
    title: i18n.t("navigation.notifications"),
    titleEng: "Notifications",
    route: "notifications",
    icon: "BellIcon",
    id: 8,
  },
];
