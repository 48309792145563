<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-lg-block" />
    </div>
    <Locale />
    <div v-if="userData" class="mx-1">
      <notification-dropdown />
    </div>
    <b-navbar-nav v-if="userData" class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p v-if="userData" class="user-name font-weight-bolder mb-0">
              {{ displayName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="computedLogoImage"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="isUserVerified"
          :to="{ name: 'account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("global.settings") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("global.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { mapState } from "vuex";
import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import NotificationDropdown from "@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue";

const tempLogo = require("@/assets/images/logo/logo.png");

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    NotificationDropdown,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      verificationStatusCodes: [11, 12],
    };
  },
  computed: {
    ...mapState("accountSettings", ["userData", "logo"]),
    ...mapState("userManagement", ["userModules"]),
    ...mapState("accountSettings", ["userApplication"]),
    displayName() {
      console.log("this.userApplication", this.userApplication)
      if (!this.userApplication) return "";
      if (this.userApplication.application.email) return this.userApplication.application.email
      if (
        this.userApplication.application.brandNameGeo ||
        this.userApplication.application.brandNameEng
      ) {
        return `${this.userApplication.application.brandNameGeo} ${this.userApplication.application.brandNameEng}`;
      }
      return this.userApplication.application.brandNameEng;
    },
    isUserVerified() {
      const e = this.userModules.error;
      if (e && this.verificationStatusCodes.includes(e.code)) return false;
      return true;
    },
    computedLogoImage() {
      return this.logo || tempLogo;
    },
  },
  methods: {
    logout() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch("auth/logout", {}).then((response) => {
        localStorage.clear();
        this.$router.replace("/login");
      });
    },
  },
};
</script>
