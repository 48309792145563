<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media pb-0"
    right
    @show="handleReadAll"
  >
    <template #button-content>
      <feather-icon
        :badge="unreadCount > 0 ? unreadCount : ''"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <p class="notification-title mb-0 mr-auto">
          {{ t.t("notifications.notifications") }}
        </p>
        <b-badge v-if="unreadCount" pill variant="light-primary">
          {{ unreadCount }} {{ t.t("global.new") }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <div
      v-if="totalCount > 0"
      class="scrollable-container media-list scroll-area"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in displayNotifications"
        :key="notification.subtitle"
      >
        <b-media @click="handleMarkAsRead(notification)">
          <p class="media-heading mb-0">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.body }}</small>
        </b-media>
      </b-link>
      <!-- Cart Footer -->
    </div>
    <li>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :disabled="!hasMore || isTableBusy"
        @click="handleGetNotifications(true)"
      >
        {{ t.t("notifications.load-more") }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      totalCount: 0,
      unreadCount: 0,
      take: 10,
      skip: 0,
      displayNotifications: [],
      hasMore: true,
    };
  },
  computed: {
    ...mapState("notificationModule", ["notificationsList", "isTableBusy"]),
    t() {
      return this.$i18n;
    },
  },
  watch: {
    notificationsList() {
      this.unreadCount = this.notificationsList.unreadCount;
    },
  },
  mounted() {
    const roles = localStorage.getItem("userRoles");
    if (roles) {
      this.handleGetNotifications();
    }
  },
  methods: {
    ...mapActions("notificationModule", [
      "getNotifications",
      "readAllNotifications",
      "markNotificationAsRead",
    ]),
    async handleReadAll() {
      try {
        await this.readAllNotifications();
        this.unreadCount = 0;
      } catch (error) {
        console.log(error);
      }
    },
    async handleMarkAsRead(notification) {
      console.log("hi", notification);
      // try {
      //   await this.markNotificationAsRead(notification.id);
      //   this.unreadCount = this.unreadCount === 0 ? 0 : this.unreadCount - 1;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async handleGetNotifications(loadMore = false) {
      if (loadMore) this.skip += this.take;
      try {
        await this.getNotifications({ take: this.take, skip: this.skip });
        this.displayNotifications = [
          ...this.displayNotifications,
          ...this.notificationsList.notifications,
        ];
        //  this throws typeerror
        // [this.totalCount, this.unreadCount] = this.notificationsList;
        this.totalCount = this.notificationsList.totalCount;
        this.unreadCount = this.notificationsList.unreadCount;
        this.hasMore = this.skip + this.take < this.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-area {
  max-height: 400px;
  overflow-y: scroll;
}

.dropdown-notification marker {
  display: none;
}

.dropdown-notification {
  list-style: none;
}
</style>
